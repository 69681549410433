.svg-map {
	width: 100%;
	height: auto;
	stroke: #fff;
	stroke-width: 1;
	stroke-linecap: round;
	stroke-linejoin: round;

	&__location {
		fill: #A9A9A9;
		cursor: normal;

		&:focus,
		&:hover {
			fill: #A9A9A9;
			outline: 0;
		}


		&[id="Araria"] {
			fill: #0052cc;
			cursor: pointer;
		}
		&[id="Purnia"] {
			fill: #dc3912;
			cursor: pointer;
		}
		&[id="Katihar"] {
			fill: #fba10f;
			cursor: pointer;
		}
		
	}
}

.ar .svg-map {
	stroke: #fff;
	&__location {
		&[id="Purnia"] {
			fill: #A9A9A9;
			cursor: pointer;
		}
		&[id="Katihar"] {
			fill: #A9A9A9;
			cursor: pointer;
		}
	}
}
.pu .svg-map {
	stroke: #fff;
	&__location {
		&[id="Araria"] {
			fill: #A9A9A9;
			cursor: pointer;
		}
		&[id="Katihar"] {
			fill: #A9A9A9;
			cursor: pointer;
		}
	}
}
.kr .svg-map {
	stroke: #fff;
	&__location {
		&[id="Araria"] {
			fill: #A9A9A9;
			cursor: pointer;
		}
		&[id="Purnia"] {
			fill: #A9A9A9;
			cursor: pointer;
		}
	}
}


