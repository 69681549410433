//Header Page css
.headerPage{height: 50px; background: #ffffff; position: fixed; width: 100%; z-index: 99; top:0px; left: 0px;}
.d_menuItem{position: relative;}
.d_menuItem a{ font-size: 0.8rem; text-decoration: none; padding: 5px 10px;padding-left: 26px;color: #212529;display: block;}
.headerPage .dropdown-menu {min-width: 12rem;}
.headerPage .d_menuItem:hover{background-color: rgba(251,161,15,0.2);}
.headerPage .d_menuItem:before { background: #425668; bottom: auto; content: ""; height: 10px; left: 10px; margin-top: 11px; position: absolute; right: auto; width: 10px; z-index: 1; border-radius: 50%;}
.headerPage .d_menuItem:after {border-left: 1px solid #425668;height: 20px;content: "";left: 14px;position: absolute;top: -9px;}
.headerPage .d_menuItem.first:after {height: 0px;}

.m_menuItem a{ font-size: 0.8rem; text-decoration: none; padding: 5px 10px;padding-left: 26px;color: #212529;display: block;}
.m_menuItem:before { background: #425668; bottom: auto; content: ""; height: 10px; left: 25px; margin-top: 11px; position: absolute; right: auto; width: 10px; z-index: 1; border-radius: 50%;}
.m_menuItem:after {border-left: 1px solid #425668;bottom: 10px;content: "";left: 29px;position: absolute;top: 15px;}

.headerPage .d_menu{ font-size: 0.9rem; font-weight: bold; padding: 2px 5px; margin-right: 20px; color: #ffffff; cursor: pointer; border-radius: 2px;}
.headerPage .d_menu a{text-decoration: none; color: #ffffff;}
.headerPage .d_menu:hover{background-color: #fba10f;}

.accessName{    background-color: #fba10f;
  position: absolute;
  top: 0px;
  left: 40%;
  padding: 0px 10px 4px 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: bold;}
//
//Home Dashboard CSS
.topBox{ display: flex; align-items: center; justify-content: space-between; padding: 10px; height: 70px; border-radius: 2px; box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;}
.homeTab{border-bottom: 1px solid #0052cc;}
.homeTab a{ color: #212529; cursor: pointer; text-decoration: none; padding: 6px 20px; font-weight: bold;display: inline-block;border:1px solid #fff;border-bottom: none; border-radius: 5px 5px 0 0;}
.homeTab a.active{background: #0052cc; color: #ffffff; cursor: default; text-decoration: none; border:1px solid #fff;border-bottom: none;}
.homeTab a:hover{border:1px solid #eee;border-bottom: none;}
.d-box{
  border-radius: 5px;
  padding: 14px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.map-pill{
    display:inline-block;
    width: 30px;
    height: 10px;
    border-radius: 0px;
    margin-right: 5px;
  }
 .custPopup{
    width: 150px;
    padding: 3px;
    position: absolute;
    right: 1%;
    top: 14%;
    background-color: #fff;
    border: 2px solid #0052cc;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0,0,0,0.3);
    transition: box-shadow 100ms ease-in;
  }
  
  .mapView .custPopup{
    width: 150px;
    padding: 3px;
    position: absolute;
    right: 5px;
    top: 45px;
    background-color: #fff;
    border: 1px solid #0052cc;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0,0,0,0.3);
    transition: box-shadow 100ms ease-in;
  }
//

.welcomeTxt{
  position: absolute;
  right: 21px;
  top: 90px;
  text-align: right;
  width: auto;
  padding: 4px 20px;
  border-radius: 5px;
  color: #fff;
}

.profileImg{
  max-height: 130px;
}
.v_status{
  display: inline;
  padding-left: 8px;
}

//Footer page css

.footerPage .socialIcon{display: inline-block; font-size: 35px; }
.footerPage .socialIcon a{color: #ffffff;}
.footerPage .socialIcon a:hover{color: #fba10f;}