body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0.8rem;
    color:#212529;
    user-select: none;
    -webkit-user-select: none
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .borderBottom{
    border-bottom: 2px solid #0052cc;
  }

  .borderPrimary{
    border: 2px solid #0052cc;
  }

  .borderDashed{
    border-bottom: 1px dashed #0052cc;
  }

  .primaryBg{background: #0052cc;}
  .cursor{ cursor: pointer;}

  a, .nav-link{color: #244cfd;}
  .list-group-item.active{background-color: #0052cc;}

  .fixHeight{min-height: 322px; max-height: 322px; overflow-y: auto;}
  .breadcrumbs{ padding: 0.25rem 1rem; background-color: #e9ecef; border-radius: 0.25rem; margin-left: 25px; font-size: 0.9rem; font-weight: 600;}
  .accordion-body {
    padding: 0rem 1rem;
  }
  .accordion-button {
    padding: 0.4rem 1.25rem;
}
.excelIcon{color: green;
  position: absolute;
  top: 16px;
  right: 5px;
  z-index: 1;
  cursor:pointer;
}

input[type=file]{
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  overflow: hidden;
}